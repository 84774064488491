var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-step", {
    attrs: {
      stepperGrpCd: "EDU_STEP_CD",
      currentStepCd: _vm.popupParam.stepCd,
      param: _vm.param,
    },
    on: {
      stepClick: _vm.stepClick,
      closePopup: _vm.closePopup,
      currentStep: _vm.currentStep,
      setRegInfo: _vm.setRegInfo,
    },
    model: {
      value: _vm.popupParam.stepCd,
      callback: function ($$v) {
        _vm.$set(_vm.popupParam, "stepCd", $$v)
      },
      expression: "popupParam.stepCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }