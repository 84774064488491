<template>
  <c-step
    stepperGrpCd="EDU_STEP_CD"
    :currentStepCd="popupParam.stepCd"
    :param="param"
    v-model="popupParam.stepCd"
    @stepClick="stepClick"
    @closePopup="closePopup"
    @currentStep="currentStep"
    @setRegInfo="setRegInfo"
    >
  </c-step>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'education-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: null,
        eduEducationId: '',
        eduCourseId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      searchResult: {
        flag: '',
        isSearch: false,
      },
      planUpdateBtnData: {
        title: 'LBLPLAN',
        flag: false,
        research: '',
        planResearch: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    param() {
      return {
        plantCd: this.popupParam.plantCd,
        eduEducationId: this.popupParam.eduEducationId,
        eduCourseId: this.popupParam.eduCourseId,
        stepCd: this.popupParam.stepCd,
        searchResult: {
          flag: this.searchResult.flag,
          isSearch: this.searchResult.isSearch,
        },
        planUpdateBtnData: this.planUpdateBtnData
      }
    },
  },
  methods: {
    currentStep(data) {
      this.popupParam.stepCd = data.step;
      this.popupParam.eduEducationId = data.key;
    },
    closePopup() {
      this.$emit('closePopup')
    },
    stepClick(step) {
      if (step === 'RAS000005' && this.searchResult.isSearch) {
        this.searchScenario.flag = uid();
        this.searchScenario.isSearch = false;
      }
    },
    setRegInfo(data) {
      this.$emit('setRegInfo', data)
    }
  }
};
</script>
